import {
  darkModePrimary,
  darkTextShadow,
  darkTextShadowXL,
  defaultShadow,
  error,
  lightModePrimary,
  lightTextShadow,
  lightTextShadowXL,
  neutral,
  success,
  warning,
} from './colors'
import { primaryFont, secondaryFont, typeScale } from './typography'

export const defaultTheme = {
  primaryColor: lightModePrimary[100],
  secondaryColor: lightModePrimary[200],
  backgroundColor: neutral[100],
  largeHeadingTextColor: lightModePrimary[200],
  buttonText: lightModePrimary[200],
  strokeColor: lightModePrimary[200],
  navBarColor: neutral[100],
  sideBarColor: neutral[100],
  textColor: lightModePrimary[300],
  textColorOnPrimary: lightModePrimary[200],
  textColorOnSecondary: neutral[100],
  textColorInverted: neutral[100],
  buttonBGColor: lightModePrimary[200],
  buttonTextColor: lightModePrimary[500],
  buttonBGActiveColor: lightModePrimary[100],
  buttonTextActiveColor: lightModePrimary[200],
  placeholderColor: neutral[800],
  selectBGColor: neutral[100],
  selectBGActiveColor: lightModePrimary[200],
  selectBGTextColor: lightModePrimary[200],
  selectBGActiveTextColor: neutral[100],
  textShadow: lightTextShadow,
  textShadowXL: lightTextShadowXL,
  disabled: neutral[400],
  textOnDisabled: neutral[300],
  status: {
    warningColor: warning[100],
    warningColorHover: warning[200],
    warningColorActive: warning[300],
    errorColor: error[100],
    errorColorHover: error[200],
    errorColorActive: error[300],
    successColor: success[100],
    successColorHover: success[200],
    successColorActive: success[300],
  },
  primaryFont,
  secondaryFont,
  defaultShadow,
  typeScale,
}

export const darkTheme = {
  primaryColor: darkModePrimary[100],
  secondaryColor: darkModePrimary[200],
  backgroundColor: darkModePrimary[300],
  largeHeadingTextColor: neutral[100],
  buttonText: neutral[100],
  strokeColor: darkModePrimary[400],
  navBarColor: darkModePrimary[300],
  sideBarColor: neutral[700],
  textColor: darkModePrimary[400],
  textColorOnPrimary: neutral[100],
  textColorOnSecondary: darkModePrimary[300],
  textColorInverted: neutral[100],
  buttonBGColor: darkModePrimary[200],
  buttonTextColor: darkModePrimary[300],
  buttonBGActiveColor: darkModePrimary[100],
  buttonTextActiveColor: darkModePrimary[200],
  placeholderColor: neutral[800],
  selectBGColor: darkModePrimary[200],
  selectBGActiveColor: darkModePrimary[100],
  selectBGTextColor: darkModePrimary[300],
  selectBGActiveTextColor: darkModePrimary[200],
  textShadow: darkTextShadow,
  textShadowXL: darkTextShadowXL,
  disabled: neutral[400],
  textOnDisabled: neutral[300],
  status: {
    warningColor: warning[100],
    warningColorHover: warning[200],
    warningColorActive: warning[300],
    errorColor: error[100],
    errorColorHover: error[200],
    errorColorActive: error[300],
    successColor: success[100],
    successColorHover: success[200],
    successColorActive: success[300],
  },
  primaryFont,
  secondaryFont,
  defaultShadow,
  typeScale,
}
