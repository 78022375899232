import React from 'react'

export const HammerIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 125"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
  >
    <path d="M66.55,20.33L49.113,2.892c-1.393-1.393-3.65-1.393-5.042,0L2.893,44.071c-1.394,1.392-1.394,3.649,0,5.042L20.33,66.549  c1.393,1.393,3.649,1.393,5.041,0L66.55,25.372C67.942,23.978,67.942,21.722,66.55,20.33z M95.542,82.938L54.715,42.109  L42.109,54.715l40.828,40.828c3.479,3.48,9.123,3.48,12.604,0C99.023,92.061,99.023,86.416,95.542,82.938z" />
  </svg>
)
