import React from 'react'

export const CheckBoxSelectionSVG = ({ fill }) => (
  <svg
    id="b3347a10-18dd-4c71-91c3-38a98340ba03"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="350"
    viewBox="0 0 963.50956 774.36439"
  >
    <title>project_completed</title>
    <rect y="719.52637" width="963.50956" height="2.24072" fill="#3f3d56" />
    <path
      d="M722.76988,398.62826s-43.21728,33.95643-21.60864,41.6738,35.4999-27.78253,35.4999-27.78253Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#ffb8b8"
    />
    <path
      d="M724.31335,386.28047l-9.26085,15.43474s15.43475,0,18.52169,23.15211l12.3478-15.43474Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#cfcce0"
    />
    <polygon
      points="811.35 675.375 812.894 721.679 794.372 732.483 786.655 727.853 788.198 675.375 811.35 675.375"
      fill="#ffb8b8"
    />
    <path
      d="M820.00875,725.84477l3.08694,49.39118-43.21727,18.52169,3.087-26.23906s12.34779-20.06517,9.26084-41.67381Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#ffb8b8"
    />
    <path
      d="M886.37813,353.86751s24.69559,64.82591,29.326,104.95624,12.3478,146.63,12.3478,146.63L943.48668,742.823l-43.21728,6.1739L881.74771,610.08421,840.07391,472.715l-9.26085,137.36919-7.71737,125.02141-40.13032,1.54347-3.087-129.65182L762.9002,441.84553l9.26085-78.71718Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#2f2e41"
    />
    <path
      d="M909.53025,787.58374s2.53155-25.92279,21.33093-11.41792l3.36465-2.47335,15.43474,33.95643s18.52169,26.23906-13.89126,29.326-29.326-29.326-29.326-29.326l-3.087-13.89126,1.54347-9.09678Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#2f2e41"
    />
    <path
      d="M820.00875,756.71426a24.52083,24.52083,0,0,1,12.34779,21.60864c0,15.43474-1.54348,16.97821-1.54348,16.97821L807.661,801.475s-15.43474-1.54348-24.69558,7.71737-55.56507,15.43474-55.56507,3.087,38.58685-32.413,38.58685-32.413,11.45915-19.35216,19.62084-17.39345,11.24864,5.04566,11.24864,6.58913-4.63042,12.34779-4.63042,12.34779S818.46527,775.236,820.00875,756.71426Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#2f2e41"
    />
    <circle cx="698.67658" cy="62.61553" r="38.58685" fill="#ffb8b8" />
    <path
      d="M845.47607,135.46592l16.97821,38.58685-46.30422,66.36939s-3.087-72.54329-12.34779-84.89108S845.47607,135.46592,845.47607,135.46592Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#ffb8b8"
    />
    <polygon
      points="705.622 152.909 713.34 132.844 719.513 134.387 727.231 128.213 738.035 94.257 753.47 105.061 773.535 307.256 648.514 310.343 665.492 169.887 680.927 129.757 692.068 115.028 699.448 137.474 705.622 152.909"
      fill="#cfcce0"
    />
    <path
      d="M859.36734,302.16112l4.723-139.6196s9.16826.70693,12.25521,5.33735,54.0216,13.89127,54.0216,13.89127l-9.26085,143.5431s38.58685,114.21708,24.69559,120.391-38.58686,4.63042-38.58686,4.63042Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#575a89"
    />
    <path
      d="M784.50884,284.41117l16.3736-94.09812-36.43876,24.64179,9.26084,67.91286-7.71737,46.30422S736.66114,457.28028,745.922,461.9107s20.06516,0,20.06516,0l18.52169-129.65183Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#575a89"
    />
    <path
      d="M775.248,211.86789l-10.80432,3.087-4.63043,61.739-7.71737,54.0216L715.0525,397.08478s18.52169-12.34779,26.23906,23.15212l40.79561-71.02631Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#575a89"
    />
    <path
      d="M895.639,386.28047s-43.21728,33.95643-21.60864,41.6738,35.49991-27.78254,35.49991-27.78254Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#ffb8b8"
    />
    <polygon
      points="803.633 340.441 789.742 357.419 771.22 325.006 778.937 311.115 803.633 340.441"
      fill="#cfcce0"
    />
    <path
      d="M918.79109,187.1723l12.34779-6.17389s9.26085,12.34779,10.80432,23.15211,9.26085,24.69558,9.26085,32.41295,21.60864,80.26066,21.60864,80.26066L923.42151,417.15s-10.80432-37.04338-33.95643-40.13033L928.05194,322.998,906.4433,284.41117Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#575a89"
    />
    <path
      d="M849.22485,88.66835l7.27645-2.91308S841.287,69.00513,820.119,70.46173l5.95362-6.55447s-14.55289-5.82608-27.78277,9.46746c-6.9547,8.03946-15.00135,17.48947-20.01758,28.13477h-7.79257l3.25232,7.16125L762.34886,115.832l11.68369-1.28631a40.01794,40.01794,0,0,0-.31706,11.87827,15.36888,15.36888,0,0,0,5.62792,10.054h0s9.02412-18.67905,9.02412-21.59213v7.28268s7.27644-6.55438,7.27644-10.92395l3.96893,5.09787,1.98446-8.011,24.47525,8.011-3.96893-6.55438,15.21435,2.18479-5.95362-8.011s17.1988,9.46746,17.86044,17.47844c.66142,8.01086,5.69558,15.60167,5.69558,15.60167S868.40817,99.5923,849.22485,88.66835Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#2f2e41"
    />
    <ellipse
      cx="730.3178"
      cy="56.44164"
      rx="4.32173"
      ry="5.40216"
      fill="#ffb8b8"
    />
    <polygon
      points="178.601 318.118 45.361 318.118 45.361 280.782 114.91 280.782 178.601 318.118"
      fill={fill}
    />
    <path
      d="M163.24032,375.81141V484.89274H595.9052V375.81141ZM331.987,466.5905H191.42577V451.21662H331.987ZM567.71976,438.039h-376.294V422.66513h376.294Zm0-28.55149h-376.294V394.11364h376.294Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#3f3d56"
    />
    <polygon
      points="178.601 141.685 45.361 141.685 45.361 104.348 114.91 104.348 178.601 141.685"
      fill={fill}
    />
    <path
      d="M163.24032,201.57411V310.65544H595.9052V201.57411ZM331.987,292.35321H191.42577V276.97933H331.987Zm81.26193-28.55149H191.42577V248.42784H413.24888Zm154.47088-28.55149h-376.294V219.87635h376.294Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#3f3d56"
    />
    <polygon
      points="178.601 490.891 45.361 490.891 45.361 453.555 114.91 453.555 178.601 490.891"
      fill={fill}
    />
    <path
      d="M163.24032,546.38825V655.46959H595.9052V546.38825Zm250.00856,90.7791H191.42577V621.79347H413.24888Zm0-28.55149H191.42577V593.242H413.24888Zm154.47088-28.55149h-376.294V564.69049h376.294Z"
      transform="translate(-118.24522 -62.8178)"
      fill="#3f3d56"
    />
    <polygon
      points="600.285 223.679 538.789 223.679 538.789 162.183 580.519 162.183 580.519 163.647 540.254 163.647 540.254 222.215 598.821 222.215 598.821 192.931 600.285 192.931 600.285 223.679"
      fill="#3f3d56"
    />
    <path
      d="M687.46572,270.45629c-1.4211-4.19614-19.242-18.45992-8.3218-20.8574l8.95523,11.94078,41.17216-41.17145,4.14089,4.14088Z"
      transform="translate(-118.24522 -62.8178)"
      fill={fill}
    />
    <polygon
      points="600.285 397.916 538.789 397.916 538.789 336.42 580.519 336.42 580.519 337.885 540.254 337.885 540.254 396.452 598.821 396.452 598.821 367.168 600.285 367.168 600.285 397.916"
      fill="#3f3d56"
    />
    <path
      d="M687.46572,444.69359c-1.4211-4.19614-19.242-18.45992-8.3218-20.8574l8.95523,11.94078,41.17216-41.17145,4.14089,4.14088Z"
      transform="translate(-118.24522 -62.8178)"
      fill={fill}
    />
    <polygon
      points="600.285 571.421 538.789 571.421 538.789 509.926 580.519 509.926 580.519 511.39 540.254 511.39 540.254 569.957 598.821 569.957 598.821 540.673 600.285 540.673 600.285 571.421"
      fill="#3f3d56"
    />
    <path
      d="M687.46572,618.1988c-1.4211-4.19614-19.242-18.45993-8.3218-20.8574l8.95523,11.94078,41.17216-41.17146,4.14089,4.14088Z"
      transform="translate(-118.24522 -62.8178)"
      fill={fill}
    />
  </svg>
)
